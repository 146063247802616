html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #427d9d;
}

.App {
  font-family: Arial, Helvetica, sans-serif;
}

/* Scrollbar Track */
::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-clip: content-box; /* Prevent background from showing behind the scrollbar handle */
}
