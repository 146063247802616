.Plans {
  background-color: #427d9d !important;
  display: flex;
  overflow: scroll;
  height: 90vh;
  font-family: Arial, Helvetica, sans-serif;
}

.main {
  flex: 1;
}

.PlanTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}
