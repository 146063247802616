.Clients {
  height: 90vh;
  overflow: scroll;
  display: flex;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.main {
  flex: 1;
}
.DataGridContainer {
  height: 100%;
  width: 100%;
}
.head {
  text-align: center;
}
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #1c1c1c;
  color: #f5ffff;
  text-align: center;
}
.table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #bdb0b0;
  color: #f5ffff;
  text-align: center;
}

.AddClient {
  border: none;
  outline: none;
  cursor: pointer;
  margin: 15px;
  width: fit-content;
  padding: 10px;
  transition: all 0.3s;
  text-decoration: none;
  border-radius: 10px;
  background-color: #9bbec8;
}
.AddClient:hover {
  color: #ffffff;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

.search-client {
  color: black;
  background-color: #ddf2fd;
  border: none;
  padding: 8px;
  margin-left: 10px;
  font-size: 14px;
  width: 100%;
}
.ClientTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background-color: #164863;
  border-radius: 10px;
  padding: 10px;
}

.edit-button {
  border: none;
  outline: none;
  cursor: pointer;
  margin: 5px;
  width: fit-content;
  transition: all 0.3s !important;
}
