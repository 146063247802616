.InternetTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background-color: #164863;
  border-radius: 10px;
  padding: 10px;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}
.status-offline {
  color: rgb(207, 58, 58) !important;
}

.status-online {
  color: rgb(21, 228, 238) !important;
}

.status-inactive {
  color: rgb(94, 109, 120) !important;
}

.status-expired {
  color: rgb(250, 169, 77) !important;
}
.status-hold {
  color: rgb(249, 220, 85) !important;
}

.DataGridContainer {
  height: 100%;
  width: 100%;
}

.add-button:hover {
  color: black !important;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}
.ModalHeader {
  background-color: #f3d7ca;
}

.ModalTitle {
  color: black;
}

.Subform {
  padding: 20px;
  background-color: white;
}

/* Styling for the DataGrid */
.MuiDataGrid-root {
  background-color: transparent;
  border: none !important;
}

.MuiDataGrid-columnsContainer {
  background-color: transparent;
}

.MuiDataGrid-columnHeaderTitle {
  color: white;
  font-weight: bold;
}
.MuiDataGrid-root::-webkit-scrollbar {
  width: 8px !important;
}
.MuiDataGrid-row {
  background-color: transparent;
  color: black;
}

.MuiCheckbox-root,
.MuiCheckbox-colorPrimary.Mui-checked {
  color: white;
}

.MuiDataGrid-toolbarContainer {
  background-color: transparent;
}

.MuiDataGrid-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.MuiDataGrid-sortIcon,
.MuiDataGrid-sortIcon svg {
  fill: white !important;
}

.MuiMenuItem-root,
.MuiMenuItem-root:hover {
  color: white;
  background-color: #f3d7ca;
}

.MuiTextField-root,
.MuiInputLabel-root,
.MuiInputBase-root,
.MuiInputBase-input {
  color: white;
}

/* Styling for the search input */
.search-input {
  color: black;
  background-color: #ddf2fd;
  border: none;
  padding: 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 14px;
  width: 200px;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
}
/* Styling for the DataGrid */
.subcheck {
  color: white !important;
}
.MuiTablePagination-root,
.MuiTablePagination-caption,
.MuiTablePagination-selectRoot,
.MuiTablePagination-select,
.MuiTablePagination-actions button {
  color: white !important;
}
.MuiDataGrid-root .MuiDataGrid-row:nth-child(odd) {
  background-color: #164863 !important; /* Alternate background color */
  color: white;
}

.MuiDataGrid-root .MuiDataGrid-row:nth-child(even) {
  background-color: #427d9d !important; /* Another alternate background color */
  color: white;
}
.edit-button {
  border: none;
  outline: none;
  cursor: pointer;
  margin: 5px;
  width: fit-content;
  transition: all 0.3s !important;
  background-color: #9bbec8;
  border-radius: 5px;
  padding: 2px;
  padding-left: 2px;
  padding-right: 2px;
  color: black !important;
}
