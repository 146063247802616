.Plans {
  background-color: #e2dcc8 !important;
  display: flex;
  overflow: scroll;
  height: 90vh;
  font-family: Arial, Helvetica, sans-serif;
}

.main {
  flex: 1;
}
.AddClient {
  border: none;
  outline: none;
  cursor: pointer;
  margin: 15px;
  width: fit-content;
  padding: 10px;
  transition: all 0.3s !important;
}
.AddClient:hover {
  color: black !important;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3) !important;
}
.search-plan {
  color: black;
  background-color: #ddf2fd !important;
  border: none;
  padding: 8px;
  margin-left: 10px;
  font-size: 14px;
  width: 100%;
}
.PlanTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background-color: #164863 !important;
  padding: 10px;
  border-radius: 10px;
}
.edit-button {
  border: none;
  outline: none;
  cursor: pointer;
  margin: 5px;
  width: fit-content;
  transition: all 0.3s !important;
  background-color: #9bbec8;
  border-radius: 5px;
  padding: 2px;
  padding-left: 2px;
  padding-right: 2px;
  color: black !important;
}
