/* dashboard.css */

/* Define transitions for card hover effect */

.Dashboard {
  background-color: #427d9d;
}
.card:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease-in-out;
}
.card {
  margin: 10px;
  background-color: #9bbec8 !important;
}
/* Define animations for card entrance */
.card-enter {
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.5s forwards;
}

/* dashboard.css */

/* Define custom colors */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
