/* sidebar.css */

.sidebar {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background-color: #427d9d;
  opacity: 1;
  transition: 0.3s;
  z-index: 999;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

.sidebar.open {
  width: 250px;
}

.sidebar-content {
  padding: 20px;
  color: white;
  place-items: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;

  border: none;
  cursor: pointer;
}

.sidebar-content h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.nav-links {
  display: flex;
  flex-direction: column; /* Stack links and icons vertically */
  gap: 10px; /* Add vertical spacing between links */
  color: white !important;
}

.nav-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white !important;
  transition: all 0.5s !important;
}
.nav-link:hover {
  text-decoration: none;
  color: black !important;
  font-size: medium;
}

.nav-link i {
  margin-right: 10px;
  background-color: transparent;
  transition: all 1s ease;
  color: black;
}

.MuiAccordion-root {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.MuiAccordionSummary-root {
  background-color: transparent;
  color: black !important;
}

.MuiAccordionDetails-root {
  background-color: transparent; /* Set the background color to transparent */
  color: black; /* Set the text color to white */
}

/* Set the color of the ExpandMoreIcon to white */
.MuiSvgIcon-root {
  color: black !important;
}

/* Handle hover state for AccordionSummary */
.MuiAccordionSummary-root:hover {
  background-color: transparent !important;
  color: black !important;
}

/* Handle hover state for AccordionDetails */
.MuiAccordionDetails-root:hover {
  background-color: transparent !important;
  color: black !important;
}
