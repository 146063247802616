.nav-inner {
  text-decoration: none;
  color: black;
}

.nav-inner:hover {
  color: black;
}

.add-button {
  border: none;
  outline: none;
  cursor: pointer;
  margin: 15px;
  width: fit-content;
  padding: 10px;
  transition: all 0.3s !important;
  text-decoration: none;
}

.search-input {
  color: black;
  background-color: #ddf2fd;
  border: none;
  padding: 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 14px;
}

.edit-button {
  border: none;
  outline: none;
  cursor: pointer;
  margin: 5px;
  width: fit-content;
  transition: all 0.3s !important;
}
