/* dashboard.css */

/* Define transitions for card hover effect */

.Dashboard {
  background-color: #427d9d;
}
.card:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease-in-out;
}
.card {
  margin: 10px;
}
/* Define animations for card entrance */
.card-enter {
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.5s forwards;
}

/* dashboard.css */

/* Define custom colors */
.card-online {
  background-color: #007bff; /* Blue color for online */
  color: #fff; /* White text color */
}

.card-offline {
  background-color: #dc3545; /* Red color for offline */
  color: #fff; /* White text color */
}

.card-expired {
  background-color: #ffc107; /* Yellow color for expired */
  color: #212529; /* Dark text color */
}

.card-hold {
  background-color: #28a745; /* Green color for on-hold */
  color: #fff; /* White text color */
}

.card-inactive {
  background-color: #6c757d; /* Gray color for inactive */
  color: #fff; /* White text color */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
