/* navbar.css */

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #164863;
  padding: 10px 20px;
}

.nav-left {
  display: flex;
  align-items: center;
}

.nav-right {
  display: flex;
  align-items: center;
}

.btn.hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.bar {
  width: 20px;
  height: 3px;
  background-color: white; /* White color for hamburger bars */
  margin: 3px 0;
}

.nav-left h3,
.nav-right h4 {
  margin: 0;
  color: white;
}

.logout {
  cursor: pointer;
  padding: 10px;
  background-color: #9bbec8; /* Darker background color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 5px;
  margin-left: 10px; /* Add margin between buttons */
  transition: all 0.3s !important;
}

.logout:hover {
  background-color: #64ccc5; /* Darker background color on hover */
  color: #333;
}

.menu-item {
  color: white; /* Dark color for menu items */
  cursor: pointer;
  padding: 10px;
}

.menu-item:hover {
  background-color: #666; /* Darker background color on hover */
  color: #fff; /* White text color on hover */
}

.dialog-title {
  background-color: white; /* Dark background color for dialog title */
  color: #fff; /* White text color for dialog title */
}

.dialog-content {
  padding: 20px;
}

.dialog-content-text {
  margin-bottom: 10px;
}

.dialog-actions {
  padding: 20px;
  justify-content: flex-end;
}

.dialog-actions button {
  margin-left: 10px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .nav-left h3 {
    font-size: 14px; /* Decrease font size for smaller screens */
    color: white;
  }

  .nav-right h4 {
    font-size: 12px; /* Decrease font size for smaller screens */
    color: white;
  }
}
