/* AdminConsole.css */

.admin-console {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.admin-details {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  margin: 5pz;
}

.admin-details h4 {
  margin: 5px;
}

.transactions {
  width: 100%;
}
