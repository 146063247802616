.internetsub {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
}

.main-content {
  flex: 1;
}

.add-subscription {
  display: flex;
}

.add-button {
  border: none;
  outline: none;
  cursor: pointer;
  margin: 15px;
  width: fit-content;
  padding: 10px;
  transition: all 0.3s !important;
  background-color: #9bbec8 !important;
}
